import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';
import '../../../../node_modules/highlight.js/styles/devibeans.min.css';

const CountSort = () => {

  return(
    <>
      <h2 className='text-center mb-3'>Сортировка Подсчётам</h2>
      <Tabs defaultActiveKey="descr" 
						className="mb-3">
        <Tab eventKey="descr" title="Описание">
          <p>
            Сортировка подсчётом&nbsp;&mdash; алгоритм сортировки, в&nbsp;котором используется диапазон чисел сортируемого массива (списка) для подсчёта совпадающих элементов.
          </p>
          <p>
            Сложность алгоритма: <b>O(n)</b>;<br/>
            Затраты памяти: <b>O(k)</b> - где <b>k</b> - диапазон значений элеменнтов сортируемого массива;
          </p>
          <p>
            Сортировка подсчётом лучше всего работает при таких условиях:
          </p>
          <ul>
            <li>
              массив очень большой&nbsp;&mdash; значений много;
            </li>
            <li>
              эти значения лежат в&nbsp;известном нам диапазоне (например, это диапазон работы какого-то датчика);
            </li>
            <li>
              диапазон намного меньше, чем размер массива, то&nbsp;есть единицы данных могут повторяться.
            </li>
          </ul>
          <p>
            <b>Принцип работы:</b>
          </p>
          <p>
          Главная идея алгоритма&nbsp;&mdash; посчитать, сколько раз встречается каждый элемент в&nbsp;массиве, а&nbsp;потом заполнить исходный массив результатами этого подсчёта. Для этого нам нужен вспомогательный массив, где мы&nbsp;будем хранить результаты подсчёта. Даже если нам надо отсортировать миллион чисел, мы&nbsp;всё равно знаем диапазон этих чисел заранее, например, от&nbsp;1&nbsp;до&nbsp;100. Это значит, что во&nbsp;вспомогательном массиве будет не&nbsp;миллион элементов, а&nbsp;сто.
          </p>
          <ol>
            <li>
              Мы&nbsp;создаём вспомогательный массив и&nbsp;на&nbsp;старте заполняем его нулями.
            </li>
            <li>
              Проходим по&nbsp;всему исходному массиву и&nbsp;смотрим очередное значение в&nbsp;ячейке.
            </li>
            <li>
              Берём содержимое этой ячейки и&nbsp;увеличиваем на&nbsp;единицу значение вспомогательного массива под этим номером. Например, если мы&nbsp;встретили число&nbsp;5, то&nbsp;увеличиваем на&nbsp;единицу пятый элемент вспомогательного массива. Если встретили 13&nbsp;&mdash; тринадцатый.
            </li>
            <li>
              После цикла во&nbsp;вспомогательном массиве у&nbsp;нас хранятся данные, сколько раз встречается каждый элемент.
            </li>
            <li>
              Теперь мы&nbsp;проходим по&nbsp;вспомогательному массиву, и&nbsp;если в&nbsp;очередной ячейке лежит что-то больше нуля, то&nbsp;мы&nbsp;в&nbsp;исходный массив столько&nbsp;же раз отправляем номер этой ячейки. Например, в&nbsp;первой ячейке вспомогательного массива лежит число 7. Это значит, что в&nbsp;исходный массив мы&nbsp;отправляем единицу 7&nbsp;раз подряд.
            </li>
          </ol>
          <p>
            В&nbsp;итоге мы&nbsp;получаем отсортированный массив без сравнения элементов.
          </p>
          <Link className='my-3 btn btn-outline-primary' to={'/sort'}>Назад</Link>
        </Tab>
        <Tab eventKey="code" title="JavaScript">
          <Highlight className='javascript'>
            {`function countSort(arr, min, max) {
  let j = 0;
  let count = [];

  for(let i = min; i <= max; i++) {
    count[i] = 0;
  }

  for(let i = 0; i < arr.length; i++) {
    count[arr[i]] += 1;
  }

  for(let i = min; i <= max; i++) {
    while (count[i] > 0) {
      arr[j] = i;
      j++;
      count[i]--;
    }
  }

  return arr;
}`}
          </Highlight>
          <Link className='my-3 btn btn-outline-primary' to={'/sort'}>Назад</Link>
        </Tab>
        <Tab eventKey="investigation" title="Код с комментариями">
          <Highlight className='javascript'>
            {`// функция принимает аргументами сортируемый массив,
// минимальное и максимальное значения элементов массива
// в данной реализации функция мутирует изначальный массив
function countSort(arr, min, max) {

  // вспомогательная переменная - счётчик
  let j = 0;

  // вспомогательный массив для подсчёта количества одинаковых значений
  let count = [];

  // подготовка вспомогательного массива
  // определяем длинну этого массива диапазоном значений
  // сортируемого массива, заполняя его начальными значениями - нулями
  for(let i = min; i <= max; i++) {
    count[i] = 0;
  }

  // основной проход по сортируемому массиву
  for(let i = 0; i < arr.length; i++) {

    // увеличиваем на 1 значение во вспомогательном массиве по индексу,
    // равному значению элемента сортируемого массива на текущем шаге
    count[arr[i]] += 1;
  }

  // проход по вспомогательному массиву
  for(let i = min; i <= max; i++) {

    // пока значение элемента на текущем шаге больше нуля
    while (count[i] > 0) {

      // записываем индекс текущей итерации в основной массив по порядку
      arr[j] = i;

      // порядок записи и непрерывность обеспечивает счётчик
      j++;

      // здесь контролируется правильное количество записываемых элементов 
      count[i]--;
    }
  }

  // возврат из функции отсортированного по возрастанию массива
  return arr;
}`}
          </Highlight>
          <Link className='my-3 btn btn-outline-primary' to={'/sort'}>Назад</Link>
        </Tab>
      </Tabs>
    </>
  )
}

export default CountSort;